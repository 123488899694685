.App {
  text-align: center;
  margin: auto;
  padding-top: 160px;
  width: 100vw;
  max-width: 1600px;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
