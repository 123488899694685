.main {
  width: auto;
  height: auto;
  margin: 0;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(248, 248, 248, 1);
  border-style: solid;
  background-color: transparent;
  color: white;
}
