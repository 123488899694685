.main {
  margin: calc(100vh * 0.05);
  padding: 0;
  width: 100vw;
  max-width: 1600px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner {
  width: 30%;
  margin: 5%;
  margin-bottom: 12%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
  background-color: blur;
}

.title {
  margin: 0;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  text-align: left;
  color: rgb(81, 38, 137);
}

.titleA {
  margin-bottom: 20px;
  font-size: 60px;
}

.titleB {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.link {
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  text-align: left;
  color: white;
  background-color: rgb(106, 80, 139);
  text-decoration: none;
}

.link:hover {
  background-color: rgb(76, 26, 141);
}
