.main {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-color: transparent;
  border-style: solid;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  object-fit: contain;
  overflow: hidden;
}
