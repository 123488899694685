.main {
  margin: 0;
  padding: 0;
  padding-top: 160px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner {
  width: auto;
  height: auto;
  margin: 1%;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.8);
}
