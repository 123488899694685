.title {
  margin: 30px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 50px;
  text-align: center;
  font-weight: 400;
  line-height: 75px;
  position: relative;
}

.icon {
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 80px;
  position: absolute;
  top: 10px;
  left: 11px;
}
.circle {
  display: block;
  overflow: visible;
  width: 100px;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 75px;
  fill: aliceblue;
}

.circle path {
  fill: none;
}
