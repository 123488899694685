.main {
  margin: 0;
  padding: 0;
  width: 305px;
  height: 263px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3.98px 0px rgba(51, 51, 51, 0.15);
  border-radius: 10px;
  text-decoration: none;
}
.image {
  margin: 0;
  padding: 0;
  width: 124px;
  height: 124px;
  border-radius: 124px;
  object-fit: cover;
  overflow: hidden;
}

.text {
  margin: 0;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.44px;
  text-align: left;
  color: black;
  text-decoration: none;
}
.like {
  margin: 0;
  padding: 00;
  width: 265px;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.likebox {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 28px;
  border-radius: 4px;
  background-color: rgba(248, 248, 248, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
