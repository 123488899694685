.main {
  width: 100%;

  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list {
  width: 90%;
  max-width: 1380px;
  margin: auto;
  margin-top: 48px;
  box-sizing: border-box;
  display: grid;
  align-self: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 305px);
  row-gap: 20px;
  column-gap: 20px;
}

.link {
  text-decoration: none;
}
