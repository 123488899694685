.main {
  width: auto;
  height: auto;
  margin: 0;
  margin-left: 2vw;
  margin-right: 2vw;
  padding: 0;
  border-color: transparent;
  border-style: solid;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
}
