body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./fonts/Roboto-Medium.ttf"), format("ttf");
}

@font-face {
  font-family: "Scriptt";
  src: url("./fonts/DancingScript-Bold.ttf"), format("ttf");
}
