.main {
  width: 90px;
  height: 90px;
  margin: 0;
  margin-left: 2vw;
  margin-right: 2vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  border-color: rgb(81, 38, 173);
  border-width: 2px;
  border-style: solid;
  background-color: aliceblue;
  border-color: rgb(189, 168, 235);
}
.main:hover {
  background-color: rgb(179, 155, 230);
}
.icon {
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
  border-radius: 70px;
  border-color: rgb(81, 38, 173);
  border-width: 3px;
  border-style: solid;
  object-fit: cover;
  overflow: hidden;
}
