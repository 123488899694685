.main {
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 190px auto 190px;
  grid-template-rows: 265px auto;
  column-gap: 0;
  row-gap: 0;
}

.image {
  margin: 0;
  padding: 0;
  width: 187px;
  height: 187px;
  border-radius: 187px;
  object-fit: contain;
  overflow: hidden;
}

.text {
  margin: 0;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: black;
  text-decoration: none;
}
.center {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(81, 38, 137, 1);
  box-sizing: border-box;
}
.left {
  margin: 0;
  padding: 0;
  width: 100%;
  padding-top: 32px;
  padding-left: 80px;
  box-sizing: border-box;
  background-color: rgba(81, 38, 137, 1);
}

.right {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  padding-top: 32px;
  padding-right: 80px;
  align-self: self-start;
  justify-items: self-end;
  background-color: rgba(81, 38, 137, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: self-end;
}

.title {
  margin: auto;
  padding: 0;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 64px;
  text-align: center;
  font-weight: 400;
  line-height: 75px;
  color: white;
}

.rol {
  margin: 0;
  margin-top: 16px;
  padding: 0;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 32px;
  text-align: center;
  font-weight: 400;
  line-height: 37.5px;
  color: white;
}
.name {
  margin: auto;
  margin-left: 32px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.desc {
  margin: 0;
  padding: 0;
  padding-top: 49px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-height: 21.79px;
  box-sizing: border-box;
}
.description {
  margin: 0;
  padding: 0;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.contact {
  margin: 0;
  padding: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactline {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  margin: 0;
  margin-right: 8px;
  padding: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 36px;
    line-height: 42.19px;
  }

  .rol {
    font-size: 20px;
    line-height: 23.44px;
  }
  .desc {
    flex-direction: column-reverse;
  }

  .description {
    width: 100%;
  }

  .center {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .main {
    grid-template-rows: 412px auto;
    grid-template-columns: 150px auto 150px;
  }

  .left {
    padding-left: 40px;
  }

  .right {
    padding-right: 40px;
  }
  .name {
    margin: 0;
    margin-left: 0;
    margin-bottom: 16px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.top {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 750px) {
  .main {
    grid-template-columns: 16px auto 16px;
  }

  .left {
    padding-left: 0;
  }

  .right {
    padding-right: 0;
  }
  .center {
    padding-top: 12px;
    justify-content: flex-end;
  }
}
