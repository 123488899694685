.main {
  width: 100%;
  height: 160px;
  margin: auto;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #512689;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
}
.title {
  margin: 30px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Scriptt", "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 50px;
  text-align: center;
  font-weight: 400;
  line-height: 75px;
  position: relative;
}

.icon {
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 80px;
  position: absolute;
  top: 10px;
  left: 11px;
}
.circle {
  display: block;
  overflow: visible;
  width: 100px;
  font-family: "Roboto-Medium", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 75px;
  fill: aliceblue;
}

.circle path {
  fill: none;
}

.left {
  margin: 0;
  margin-left: 2vw;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.right {
  margin: 0;
  margin-right: 2vw;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
}

.text {
  margin: auto;
  margin-top: 16px;
  padding: 0;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 23.44px;
}

@media screen and (max-width: 1000px) {
  .left {
    margin-left: 40px;
  }

  .right {
    margin-right: 40px;
  }
}

@media screen and (max-width: 750px) {
  .main {
    width: 100%;
    height: 281px;
  }

  .title {
    margin: auto;
    font-size: 36px;
    line-height: 42.19px;
  }

  .titleblock {
    width: 100%;
    height: 153px;
  }

  .text {
    font-size: 16px;
    line-height: 18.75px;
  }
  .left {
    margin-left: 12px;
    margin-top: 12px;
  }

  .right {
    margin-right: 12px;
    margin-top: 12px;
  }
}
